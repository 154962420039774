module.exports = [{
      plugin: require('/Users/Jesse/Desktop/followingjesse/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Jesse/Desktop/followingjesse/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Jesse/Desktop/followingjesse/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":100,"wrapperStyle":""},
    },{
      plugin: require('/Users/Jesse/Desktop/followingjesse/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"process.env.GOOGLE_ANALYTICS_ID","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"optimizeId":"YOUR_GOOGLE_OPTIMIZE_TRACKING_ID","experimentId":"YOUR_GOOGLE_EXPERIMENT_ID","variationId":"YOUR_GOOGLE_OPTIMIZE_VARIATION_ID","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"example.com"},
    },{
      plugin: require('/Users/Jesse/Desktop/followingjesse/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-82776520-1"},
    },{
      plugin: require('/Users/Jesse/Desktop/followingjesse/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
